import validate from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/middleware/auth.global.js";
import manifest_45route_45rule from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "exclude-route": () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/middleware/exclude-route.js")
}