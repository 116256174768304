import { computed } from 'vue';
import { format as formatDateFns } from 'date-fns';
import { get as getField } from 'lodash-es';

export function formatDate (date, format) {
  if (!date) return date;
  format ||= 'MMMM dd, yyyy'
  return formatDateFns(new Date(date), format);
}

export function formatPersonName (name) {
  return [
    name?.firstName,
    name?.lastName,
  ].filter(Boolean).join(' ');
}

export function formatCurrency (amount, currency) {
  if (typeof amount !== 'number') return amount;
  try {
    const formatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    }).format(amount);
    if (!currency) return formatted.replace('$', '');
    return formatted;
  } catch (error) {
    console.warn(error);
    // fallback to default USD and remove currency symbol
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount).replace('$', '');
  }
}

const MAPPERS = {
  date: formatDate,
  personName: formatPersonName,
  currency: formatCurrency,
};

export function useFormatting (items, mappers) {
  if (!items) throw new Error('items is required');
  if (!mappers) throw new Error('mappers is required');
  mappers = { ...mappers };
  for (const [key, mapper] of Object.entries(mappers)) {
    const mapperconf = typeof mapper === 'object' ? mapper : {
      type: typeof mapper === 'string' ? mapper : null,
      handler: typeof mapper === 'function' ? mapper : null,
      conf: null,
      key: typeof mapper === 'string' ? mapper : key,
    };
    mapperconf.key ||= key;
    // preset mapper
    if (mapperconf.type && !mapperconf.handler) {
      mapperconf.handler = MAPPERS[mapperconf.type];
    }
    // key mapper
    if (!mapperconf.handler) {
      mapperconf.handler = v => v;
    }
    // invalid mapper, skip
    if (typeof mapperconf.handler !== 'function') {
      throw new Error(`Invalid mapper for ${key}`);
    }
    mappers[key] = mapperconf;
  }
  return computed(() => items.value.map(item => {
    const mapped = { _raw: item };
    for (const [key, mapperconf] of Object.entries(mappers)) {
      const valueRaw = getField(item, mapperconf.key);
      const value = typeof mapperconf.value === 'function'
        ? mapperconf.value(valueRaw, item)
        : valueRaw;
      const conf = typeof mapperconf.conf === 'function'
        ? mapperconf.conf(value, item)
        : mapperconf.conf;
      mapped[key] = mapperconf.handler(value, conf, item);
    }
    return mapped;
  }));
}
