export default defineNuxtPlugin((nuxtApp) => {
  if (typeof window !== 'undefined') {
    window.onload = () => {
      window.lc_id = '464182930632';
      window.lc_dc = 'parmazip';
      var chatWidget = document.createElement('app-chat-box');
      chatWidget.setAttribute('id', 'widget');
      document.body.insertAdjacentElement('beforeend', chatWidget);
      var deskuInstall = document.createElement('script');
      deskuInstall.src = 'https://widget.desku.io/chat-widget.js';
      deskuInstall.setAttribute('defer', true);
      document.body.insertAdjacentElement('beforeend', deskuInstall);
    }
  }
});
