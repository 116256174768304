import { useSdk } from './use-sdk';
import { storeToRefs, defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    initialized: false,
    currentUser: null,
  }),
  actions: {
    async init () {
      const sdk = useSdk();
      this.currentUser = await sdk.currentUser();
      this.initialized = true;
    },
    async signin (data) { 
      const sdk = useSdk();
      const res = await sdk.signinWithEmail(data.email, data.password);
      this.currentUser = await sdk.currentUser();
      this.initialized = true;
      return res;
    },
    async signout () {
      const sdk = useSdk();
      await sdk.signout();
      this.currentUser = await sdk.currentUser();
      this.initialized = false;
    },
  },
});

export function useAuth () {
  const { signin, signout, init } = useAuthStore();
  const sdk = useSdk();
  const SERVICE_NAME = 'authentication';

  async function signinWithToken (token) {
    return sdk.signinWithToken(token);
  }
  const getToken = () => sdk.token();
  const register = async (data, skipSignIn = true) => {
    await sdk.create('accounts', data);
    if (skipSignIn) return;
    const { email, password } = data;
    await signout();
    const account = await signin({ email, password });
    init();
    return account;
  };
  const accountExists = async (email) => {
    const result = await sdk.create(SERVICE_NAME, {
      action: 'checkUniqueIdentity',
      identityKey: 'email',
      identity: email,
    });
    const isUnique = result?.unique;
    return !isUnique;
  };
  const sendVerificationEmail = async (opts) => {
    const payload = Object.assign({
      action: 'sendVerificationEmail',
    }, opts);
    return sdk.create(SERVICE_NAME, payload);
  };
  const applyActionCode = async (opts) => {
    const payload = Object.assign({
      action: 'applyActionCode',
    }, opts);
    return sdk.create(SERVICE_NAME, payload);
  };

  return {
    ...storeToRefs(useAuthStore()),
    accountExists,
    applyActionCode,
    getToken,
    init,
    register,
    sendVerificationEmail,
    signin,
    signinWithToken,
    signout,
  };
}

export function useCurrentUser () {
  return useAuth().currentUser;
}
