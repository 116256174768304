export function initializeCalcom (opts) {
  if (initializeCalcom._loaded) return;
  initializeCalcom._loaded = true;

  const embedUrl = opts?.embedUrl || 'https://cal.com/embed.js'; 
  const originUrl = opts?.originUrl;

  // calcom snippet
	(function (C, A, L) {
    let p = function (a, ar) {
      a.q.push(ar);
    };
    let d = C.document;
    C.Cal = C.Cal || function () {
      let cal = C.Cal;
      let ar = arguments;
      if (!cal.loaded) {
        cal.ns = {};
        cal.q = cal.q || [];
        d.head.appendChild(d.createElement("script")).src = A;
        cal.loaded = true;
      }
      if (ar[0] === L) {
        const api = function () {
          p(api, arguments);
        };
        const namespace = ar[1];
        api.q = api.q || [];
        typeof namespace === "string"
          ? (cal.ns[namespace] = api) && p(api, ar)
          : p(cal, ar);
        return;
      }
      p(cal, ar);
    };
  })(window, embedUrl, "init");
  const initopts = {};
  if (originUrl) initopts.origin = originUrl;
  window.Cal("init", initopts);
  console.log(`Calcom embed loaded from ${embedUrl}`, initopts);
}

export function useCalcom (opts) {
  const embedInline = (selector, opts) => {
    if (!selector) throw new Error('selector is required');
    const calLink = opts.calLink;
    if (!calLink) throw new Error('calLink is required');
    initializeCalcom();
    const inlineconf = {
      elementOrSelector: selector,
      calLink,
    };
    if (opts?.config) inlineconf.config = opts.config;
    console.log(`Calcom embedInline ${selector} ${calLink}`, inlineconf);
    window.Cal("inline", inlineconf);
    if (typeof opts?.bookingSuccessful === 'function') {
      window.Cal("on", {
        action: "bookingSuccessful",
        callback: evt => {
          const clink = [
            evt.detail?.data?.eventType?.profile?.username,
            evt.detail?.data?.eventType?.slug,
          ].filter(Boolean).join('/');
          console.warn('Calcom on bookingSuccessful', clink, evt.detail, evt);
          if (clink !== calLink) return;
          opts.bookingSuccessful(evt.detail?.data);
        },
      });
    }
  };

  return {
    embedInline,
  };
}
