import revive_payload_client_uyzT55Ydto from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rkg0PfE49T from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_50g8PaeIRg from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_F6O5xOtvhN from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_p07rPL1U6v from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_tHIE0tmymz from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/.nuxt/components.plugin.mjs";
import prefetch_client_yGUtYJANn5 from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_bYMMKrhT01 from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt-gtag@0.5.9/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_13Xlpe8Bem from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@9.6.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import desku_client_WKdjHxbp3Z from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/plugins/desku.client.js";
import calcom_client_cUiblCjJea from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/plugins/calcom.client.js";
import jitsi_client_cV48pmpAsP from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/plugins/jitsi.client.js";
import capacitor_client_hLH68KJ0Wz from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/plugins/capacitor.client.js";
import vue_picture_cropper_client_KdHUT7kXCB from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/plugins/vue-picture-cropper.client.js";
export default [
  revive_payload_client_uyzT55Ydto,
  unhead_rkg0PfE49T,
  router_50g8PaeIRg,
  payload_client_F6O5xOtvhN,
  check_outdated_build_client_p07rPL1U6v,
  plugin_vue3_tHIE0tmymz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yGUtYJANn5,
  plugin_client_bYMMKrhT01,
  chunk_reload_client_13Xlpe8Bem,
  desku_client_WKdjHxbp3Z,
  calcom_client_cUiblCjJea,
  jitsi_client_cV48pmpAsP,
  capacitor_client_hLH68KJ0Wz,
  vue_picture_cropper_client_KdHUT7kXCB
]