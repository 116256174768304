import { useRuntimeConfig } from '#imports';
import { initializeCalcom } from '@/composables/use-calcom';

export default defineNuxtPlugin((nuxtApp) => {
  if (typeof window === 'undefined') return;
  const runtimeConfig = useRuntimeConfig();
  initializeCalcom({
    embedUrl: runtimeConfig.public?.calcomEmbedUrl,
    originUrl: runtimeConfig.public?.calcomOriginUrl,
  });
});
