import { useRuntimeConfig } from '#imports';
import { initializeJitsi } from '@/composables/use-jitsi';

export default defineNuxtPlugin(async (nuxtApp) => {
  if (typeof window === 'undefined') return;
  const runtimeConfig = useRuntimeConfig();
  await initializeJitsi({
    domain: runtimeConfig.public?.jitsiDomain,
  });
});
