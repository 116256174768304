import { default as reset_45passwordwLREUpAz1iMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/auth-management/reset-password.vue?macro=true";
import { default as auth_45managementmmpeRrfIYzMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/auth-management.vue?macro=true";
import { default as _91room_93B94naajqavMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/calls/[room].vue?macro=true";
import { default as patient_45appointmentsdiu9vdnkkFMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-appointments.vue?macro=true";
import { default as patient_45billing4HEKGGrGNEMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-billing.vue?macro=true";
import { default as patient_45notifsv5JWqXyUvaMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-notifs.vue?macro=true";
import { default as patient_45profilezVpXIfPS5FMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-profile.vue?macro=true";
import { default as patient_45recordsJJpyAvzReAMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-records.vue?macro=true";
import { default as patientXIjyIruxeuMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient.vue?macro=true";
import { default as pharmacy_45notifssJqKSzHeXEMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-notifs.vue?macro=true";
import { default as pharmacy_45pharmacistsNqPVo0sWagMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-pharmacists.vue?macro=true";
import { default as pharmacy_45prescription_45orderseONyitWxtCMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-prescription-orders.vue?macro=true";
import { default as pharmacy_45profileNZuZlO27kOMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-profile.vue?macro=true";
import { default as pharmacy_45stripe_45connectmkdJvAD79wMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-stripe-connect.vue?macro=true";
import { default as pharmacy_45transactionsS5vfLLw4ypMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-transactions.vue?macro=true";
import { default as pharmacyLp0SKV6VD6Meta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy.vue?macro=true";
import { default as dashboardyRG4fnjM75Meta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard.vue?macro=true";
import { default as indexSABuGSSdLZMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/demo/index.vue?macro=true";
import { default as patientyBxl8LcXzWMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/demo/patient.vue?macro=true";
import { default as pharmacistOYPpmeztnQMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/demo/pharmacist.vue?macro=true";
import { default as index1dNyrRzGMtMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/directory/index.vue?macro=true";
import { default as eulahKQBuRKegHMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/eula.vue?macro=true";
import { default as forgot_45password9Ns39KbL15Meta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/forgot-password.vue?macro=true";
import { default as index_45next095B4ljDfOMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/index-next.vue?macro=true";
import { default as indexlKvggZ9IJbMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/index.vue?macro=true";
import { default as loginUP7UaFou4mMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/login.vue?macro=true";
import { default as our_45journeyvhQZ2gFVamMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/our-journey.vue?macro=true";
import { default as patients6GfLQjqFfVMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/patients.vue?macro=true";
import { default as _91id_93yrodZKQiknMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/pharmacist/[id].vue?macro=true";
import { default as index9Oig5ED38WMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/pharmacist/index.vue?macro=true";
import { default as privacy_45policyDF9jOgzoQTMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/privacy-policy.vue?macro=true";
import { default as account_45typeexnUCnX8X0Meta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/account-type.vue?macro=true";
import { default as account4ga9Yi8v0MMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/account.vue?macro=true";
import { default as personal_45detailsBbzKCjDPKlMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/personal-details.vue?macro=true";
import { default as successrCTBLjwwTnMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/success.vue?macro=true";
import { default as verify_45email3aHHcNHhORMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/verify-email.vue?macro=true";
import { default as signupE9bezEfuSZMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup.vue?macro=true";
import { default as terms_45of_45useiSsS3I8gWGMeta } from "/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/terms-of-use.vue?macro=true";
export default [
  {
    name: "auth-management",
    path: "/auth-management",
    meta: auth_45managementmmpeRrfIYzMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/auth-management.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-management-reset-password",
    path: "reset-password",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/auth-management/reset-password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "calls-room",
    path: "/calls/:room()",
    meta: _91room_93B94naajqavMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/calls/[room].vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-patient-appointments",
    path: "patient-appointments",
    meta: patient_45appointmentsdiu9vdnkkFMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-appointments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-billing",
    path: "patient-billing",
    meta: patient_45billing4HEKGGrGNEMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-billing.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-notifs",
    path: "patient-notifs",
    meta: patient_45notifsv5JWqXyUvaMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-notifs.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-profile",
    path: "patient-profile",
    meta: patient_45profilezVpXIfPS5FMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-records",
    path: "patient-records",
    meta: patient_45recordsJJpyAvzReAMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient-records.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient",
    path: "patient",
    meta: patientXIjyIruxeuMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/patient.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy-notifs",
    path: "pharmacy-notifs",
    meta: pharmacy_45notifssJqKSzHeXEMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-notifs.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy-pharmacists",
    path: "pharmacy-pharmacists",
    meta: pharmacy_45pharmacistsNqPVo0sWagMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-pharmacists.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy-prescription-orders",
    path: "pharmacy-prescription-orders",
    meta: pharmacy_45prescription_45orderseONyitWxtCMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-prescription-orders.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy-profile",
    path: "pharmacy-profile",
    meta: pharmacy_45profileNZuZlO27kOMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy-stripe-connect",
    path: "pharmacy-stripe-connect",
    meta: pharmacy_45stripe_45connectmkdJvAD79wMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-stripe-connect.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy-transactions",
    path: "pharmacy-transactions",
    meta: pharmacy_45transactionsS5vfLLw4ypMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy-transactions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pharmacy",
    path: "pharmacy",
    meta: pharmacyLp0SKV6VD6Meta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/dashboard/pharmacy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/demo/index.vue").then(m => m.default || m)
  },
  {
    name: "demo-patient",
    path: "/demo/patient",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/demo/patient.vue").then(m => m.default || m)
  },
  {
    name: "demo-pharmacist",
    path: "/demo/pharmacist",
    meta: pharmacistOYPpmeztnQMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/demo/pharmacist.vue").then(m => m.default || m)
  },
  {
    name: "directory",
    path: "/directory",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "eula",
    path: "/eula",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/eula.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9Ns39KbL15Meta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index-next",
    path: "/index-next",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/index-next.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexlKvggZ9IJbMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginUP7UaFou4mMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "our-journey",
    path: "/our-journey",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/our-journey.vue").then(m => m.default || m)
  },
  {
    name: "patients",
    path: "/patients",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/patients.vue").then(m => m.default || m)
  },
  {
    name: "pharmacist-id",
    path: "/pharmacist/:id()",
    meta: _91id_93yrodZKQiknMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/pharmacist/[id].vue").then(m => m.default || m)
  },
  {
    name: "pharmacist",
    path: "/pharmacist",
    meta: index9Oig5ED38WMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/pharmacist/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupE9bezEfuSZMeta || {},
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: "signup-account-type",
    path: "account-type",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/account-type.vue").then(m => m.default || m)
  },
  {
    name: "signup-account",
    path: "account",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/account.vue").then(m => m.default || m)
  },
  {
    name: "signup-personal-details",
    path: "personal-details",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "signup-success",
    path: "success",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/success.vue").then(m => m.default || m)
  },
  {
    name: "signup-verify-email",
    path: "verify-email",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/signup/verify-email.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/home/runner/work/parmazip-website-canada/parmazip-website-canada/src/pages/terms-of-use.vue").then(m => m.default || m)
  }
]