// plugins/capacitor.js

import { Camera } from '@capacitor/camera';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

export default defineNuxtPlugin ((nuxtApp) => {
  if (process.client) {
    // Initialize the PWA Elements (Camera, etc.)
    defineCustomElements(window);
  }

  // Make Camera available globally
  nuxtApp.provide('camera', Camera);
});
